import React from "react";
import soundcloud from '../../assets/soundcloud-logo.png';
import discogs from '../../assets/discogs.png';
import beat from '../../assets/beat.png';
import facebook from '../../assets/facebook.png';
import instagram from '../../assets/instagram.png';
import spotify from '../../assets/spotify.png';
import './social.css'; // Import the CSS file

const Social = () => {
  return (
    <div className="home__social">
      <a
        href="https://www.instagram.com/apoena_/?igsh=MTlldjg4aXRha2FqbQ%3D%3D"
        className="home__social-icon"
        target="_blank"
        rel="noreferrer"
      >
        <img className="social-icon" src={instagram} alt="Instagram" />
      </a>

      <a
        href="https://www.facebook.com/henrique.casanova?rdid=5qFQyb0fVQRJ5Vjm&share_url=https%3A%2F%2F"
        className="home__social-icon"
        target="_blank"
        rel="noreferrer"
      >
        <img className="social-icon" src={facebook} alt="Facebook" />
      </a>

      <a
        href="https://www.discogs.com/artist/1841445-Apoena"
        className="home__social-icon"
        target="_blank"
        rel="noreferrer"
      >
        <img className="social-icon" src={discogs} alt="Discogs" />
      </a>

      <a
        href="https://soundcloud.com/apoena51"
        className="home__social-icon"
        target="_blank"
        rel="noreferrer"
      >
        <img className="social-icon" src={soundcloud} alt="SoundCloud" />
      </a>

      <a
        href="https://www.beatport.com/artist/apoena/85087"
        className="home__social-icon"
        target="_blank"
        rel="noreferrer"
      >
        <img className="social-icon" src={beat} alt="Beatport" />
      </a>

      <a
        href="https://open.spotify.com/artist/7BZXGUM5lLUZsKrCLeIpsK"
        className="home__social-icon"
        target="_blank"
        rel="noreferrer"
      >
        <img className="social-icon" src={spotify} alt="Spotify" />
      </a>
    </div>
  );
};

export default Social;